import * as React from 'react';
import LayoutTrainingen from '../components/layout-trainingen';

const TrainingenPage = () => {
  return <LayoutTrainingen> 
 
  </LayoutTrainingen>;
};

export default TrainingenPage;
